export function resFontSize( minFont=20, maxFont=80, minScreen=320, maxScreen=1200, units="px" ) {
	var baseCSS = `font-size: ${minFont}${units} !important;`;
	if (maxFont){
		var output = baseCSS.concat(`
			@media (min-width: ${minScreen}${units}) and (max-width: ${maxScreen}${units}){
				font-size: calc(${minFont}${units} + ${maxFont - minFont} * (100vw - ${minScreen}${units}) / ${maxScreen - minScreen}) !important;
			}
			@media (min-width: ${maxScreen}${units}){
				font-size: ${maxFont}${units} !important;
			}
		`);
	}
	return output;
}