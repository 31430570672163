import styled from 'styled-components'
import { A } from '@bootstrap-styled/v4'


export const ButtonInline = styled(A)`
	display: inline-block;
	letter-spacing: 0.15em;
	position: relative;
	font-weight: 400;
	margin-left: 3.5em;
	margin-right: 0.5em;
	padding: 0 0 0.35em;
	&:before{
		content: '';
		height: 1px;
		width: 2em;
		display: inline-block;
		background-color: ${p => p.theme['$link-color']};
		position: absolute;
		top: calc(50% - 0.18em);
		left: -3em;
	}
	&:after{
		position: absolute;
		right: 3px;
		bottom: 0;
		left: 0;
		content: "";
		border-bottom: 1px solid;
		display: block;
		transform: scaleX(0);
		transform-origin: 100% 50%;
		transition: transform .3s ease;
	}
	&:hover{
		text-decoration: none;
		&:after{
			transform-origin: 0 50%;
			transform: scaleX(1);
		}
	}
	&:focus{
		outline: none;
		box-shadow: none;
	}
	&.alt-1{
		color: ${p => p.theme.$bodyColor};
		&:before{
			background-color: ${p => p.theme.color.body};
		}
		&:hover:after{
			color: ${p => p.theme.color.body};
		}
	}
	&.alt-2{
		color: #fff;
		&:before{
			background-color: #fff;
		}
		&:hover:after{
			color: #fff;
		}
	}
	&.alt-3{
		color: #000;
		&:before{
			background-color: #000;
		}
		&:hover:after{
			color: #000;
		}
	}
`;