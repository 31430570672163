import React from "react"
import Navigation from '../components/Navigation/Navigation'
import GlobalStyle from '../assets/styles/globalStyles'
import BootstrapProvider from '@bootstrap-styled/provider/lib/BootstrapProvider'
import Color from '@bootstrap-styled/color'
import FullHeightBox from '../components/Styles/FullHeightBox'
import { AnimatePresence } from 'framer-motion'



const primary = Color('#b3dbd3').saturate(0.95).hex();

const vars = {
	color: {
		primary,
		primaryLight: Color(primary).lighten(0.2).hex(),
		primaryBg: 'linear-gradient(45deg, #f4d6db, #b3dbd3, #69b7eb)',
		body: '#f2f2f2',
		headings: '#f5f5f5',
		bg: '#202020'
	},
	font: {
		weightBold: '600'
	}
}


const theme = {
	'$font-family-base': 'Montserrat, sans-serif',
	'$headings-font-weight': '700',
	'$body-bg': vars.color.bg,
	'$body-color': vars.color.body,
	'$primary': vars.color.primary,
	'$dark': '#202020',
	'$headings-color': vars.color.headings,
	'$container-max-widths': {
    sm: '540px',
    md: '720px',
    lg: '960px',
		xl: '1140px',
		xxl: '1300px'
  },
  '$grid-breakpoints': {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
		xl: '1200px',
		xxl: '1452px'
	},
	'$spacers' : {
		0: 0,
		'0-25': '0.25rem',
		'0-5': '0.5rem',
		1: '1rem',
		2: '2rem',
		3: '3rem',
		4: '4rem',
		5: '5rem'
	},
	'$enable-shadows': true,
	'$enable-rounded' : false,
	'$border-radius-lg':'2rem',
	'$btn-primary-color': vars.color.bg,
	'$btn-primary-bg': vars.color.primaryBg,
	'$btn-line-height': '1',
	'$btn-padding-x': '1.5em',
	'$btn-padding-y': '0.75em',
	'$btn-padding-x-sm': '1.5em',
	'$btn-padding-y-sm': '0.75em',
	'$btn-font-weight': vars.font.weightBold,
	...vars
}

const MainLayout = ({children}) => (
  <BootstrapProvider reset={true} theme={theme}>
		<GlobalStyle />
			<Navigation />
			<FullHeightBox className="pr-md-5 pb-4 pb-md-0">
				<AnimatePresence exitBeforeEnter>
					{children}
				</AnimatePresence>
			</FullHeightBox>
  </BootstrapProvider>
);

export default MainLayout
