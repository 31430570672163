import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { Button, A } from '@bootstrap-styled/v4'
import styled from 'styled-components'
import FlagPL from '../../../static/assets/icons/pl.inline.svg'
import FlagEN from '../../../static/assets/icons/en.inline.svg'
import classNames from 'classnames'
import bp from '../../assets/styles/breakpoints'

const languageName = {
  en: <FlagEN />,
  pl: <FlagPL />
}

const LangBox = styled.div`
	position: absolute;
	top: calc(100% + 1rem);
	right: 1rem;
	.btn.btn-link{
		margin: 0 0 0.5rem;
		padding: 0.125rem 0.125rem 0.125rem 0.85rem;
		display: block;
		line-height: 0;
		&:after{
			display: none;
		}
		&:before{
			height: 6px;
			width: 6px;
			left: 0;
			border-radius: 3px;
			top: calc(50% - 3px);
			background-color: rgba(255,255,255, 0.7);
			transition: background-color 0.3s;
		}
		&:hover:before{
			background-color: #fff;
		}
		&.active:before{
			background-color: ${p=>p.theme.color.primary};
		}
	}
	svg{
		width: 1.5rem;
		height: auto;
	}
	${bp.down('sm')}{
		position: fixed;
		top: 0.75rem;
		.btn.btn-link{
			display: inline-block;
			margin-bottom: 0;
			padding: 0.5rem 0.5rem 0.5rem 0.85rem;
		}
	}
`;

const Language = () => {
  return (
    <LangBox>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <Button
							tag={A}
							color="link"
              key={language}
              onClick={() => changeLocale(language)}
							className={classNames( {'active': currentLocale === language} )}
            >
              {languageName[language]}
            </Button>
          ))
        }
      </IntlContextConsumer>
    </LangBox>
  )
}

export default Language