import styled from 'styled-components'


const Box = styled.main`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${p=>p.theme.color.bg};
	overflow: auto;
`;

export default Box;