import { createGlobalStyle } from 'styled-components';
import { Button } from '@bootstrap-styled/v4'
import bp from './breakpoints'
import { ButtonInline } from '../../components/Elements/Buttons/Buttons-1_0_0'
import { resFontSize } from './mixins'

const GlobalStyle = createGlobalStyle`
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
	html{
		font-size: 13px;
		${bp.up('xl')}{
			font-size: 14px;
		}
	}
	body{
		background-color: ${p=>p.theme.color.bg};
	}
	a{
		-webkit-tap-highlight-color: transparent;
	}
	strong{
		font-weight: 600;
	}
	.resFont-big{
		${resFontSize(30,120,360,1920)};
	}
	.resFont-1{
		${resFontSize(26,60,360,1920)};
	}
	.resFont-2{
		${resFontSize(22,40,360,1920)};
	}
	.fz-big{
		font-size: 3rem;
		${bp.up('xl')}{
			background-color: ${p => p.theme.color.primaryLight};
			color: white;
			border-radius: ${p => p.theme['$border-radius-lg']};
		}
	}
	.content-box{
		text-align: center;
		margin-left: auto;
		max-width: 1300px;
		${bp.down('md')}{
			margin: 0;
		}
		&.center{
			margin-right: auto;
		}
		&.portfolio{
			max-width: unset;
			text-align: left;
			padding-top: 3rem;
			.resFont-1{
				text-align: center;
			}
			${bp.down('md')}{
				.left{
					order: 1;
				}
			}
			.portfolio-box{
				${bp.down('sm')}{
					margin-right: 0;
					margin-left: 0;
					.left,
					.right{
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
	${Button}{
		&.btn-primary{
			&:hover,
			&:focus,
			&:active{
				background: ${p=>p.theme.color.primaryBg};
				background-size: 140%;
				background-position: left center;
			}
		}
		&.btn-link{
			${ButtonInline.componentStyle.rules}
		}
		&.btn-xs{
			font-size: 0.75rem;
		}
	}
	a{
		text-decoration: none;
	}
`;

export default GlobalStyle;