import React from 'react';
import styled from 'styled-components';
import bp from '../../assets/styles/breakpoints'
import LogoZnak from '../../../static/assets/icons/rk-logo-znak.inline.svg'
import Linkedin from '../../../static/assets/icons/linkedin.inline.svg'
import { FormattedMessage, Link, injectIntl } from "gatsby-plugin-intl"
import Language from './language'

const NavigationWrapper = styled.nav`
	position: fixed;
	top: 0.5rem;
	right: 0.5rem;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-family: 'Montserrat', sans-serif;
	flex-wrap: wrap;
	z-index: ${p=>p.theme['$zindex-navbar']};
	background-color: ${p=>p.theme.color.bg};
	${bp.down('sm')}{
		width: 100%;
		right: unset;
		left: 0;
		top: unset;
		bottom: 0;
		padding-left: 1rem;
		padding-bottom: 0.5rem;
	}
	@media (hover: hover){
		right: 1.25rem;
	}
`;

const Logo = styled.div`
	font-weight: 700;
	font-size: 20px;
	a{
		display: block;
		line-height: 1;
	}
	img, svg{
		height: 64px;
		width: auto;
	}
	${bp.down('sm')}{
		flex: 0 0 auto;
		text-align: center;
		img,svg{
			max-height: 3.5rem;
		}
	}
`;

const NavigationList = styled.ul`
	list-style: none;
	text-align: center;
	padding: 1rem 0;
	flex: 1;
	white-space: nowrap;
	overflow: auto;
	${bp.up('md')}{
		padding: 0;
		position: fixed;
		bottom: -1rem;
		transform: rotate(90deg);
		transform-origin: right;
		right: 2.5rem;
		width: calc(100vh - 11.5rem);
		height: 4rem;
		display: flex;
		align-items: center;
		margin-bottom: 0;
		@media (hover: hover){
		right: 3.5rem;
		}
	}
`;

const NavigationListItem = styled.li`
	font-weight: 400;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 0.25em;
	font-size: 0.8rem;
	a{
		text-decoration: none;
		color: inherit;
		position: relative;
		padding: 0.5em 0;
		transition: all .5s;
		display: inline-block;
		@media (hover: hover){
			&:hover{
				color: ${p=>p.theme.color.primary};
				&:after{
					transform-origin: 0 50%;
					transform: scaleX(1);
				}
			}
		}
		&:after{
			position: absolute;
			right: 3px;
			bottom: 0;
			left: 0;
			content: "";
			border-bottom: 1px solid;
			display: block;
			transform: scaleX(0);
			transform-origin: 100% 50%;
			transition: transform .3s ease;
		}
		&.active{
			color: ${p=>p.theme.color.primary};
			&:after{
				transform-origin: 0 50%;
				transform: scaleX(1);
			}
		}
	}
	${bp.up('md')}{
		text-align: center;
		margin-right: 2rem;
		letter-spacing: 0.4em;
	}
	${bp.down('sm')}{
		margin: 0 1rem;
	}
`;

const LinkedinLi = styled.li`
	display: inline-block;
	a{
		display: block;
		width: 1.25rem;
		line-height: 0;
		transform: rotate(-90deg);
		&:hover{
			svg path{
				fill: ${p=>p.theme.color.primary};
			}
		}
	}
	svg{
		width: 100%;
		height: auto;
		path{
			transition: fill 0.3s;
			fill: rgba(255,255,255,.65);
		}
	}
	${bp.down('sm')}{
		margin: 0 1rem;
		vertical-align: middle;
		a{
			transform: rotate(0);
		}
	}
`;

const Navigation = () => (
	<NavigationWrapper>
		<Logo>
			<Link to="/"><LogoZnak /></Link>
		</Logo>
		<Language />
		<NavigationList>
			<NavigationListItem><Link to="/o-mnie" activeClassName="active"><FormattedMessage id="menu.about" /></Link></NavigationListItem>
			<NavigationListItem><Link to="/portfolio" activeClassName="active"><FormattedMessage id="menu.portfolio" /></Link></NavigationListItem>
			<NavigationListItem><Link to="/kontakt" activeClassName="active"><FormattedMessage id="menu.contact" /></Link></NavigationListItem>
			<LinkedinLi><a href="https://www.linkedin.com/in/rafa%C5%82-korze%C5%84-1018061a1/"><Linkedin /></a></LinkedinLi>
		</NavigationList>
	</NavigationWrapper>
);

export default injectIntl(Navigation);